// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-c-js": () => import("./../src/pages/c.js" /* webpackChunkName: "component---src-pages-c-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-orders-add-js": () => import("./../src/pages/orders/add.js" /* webpackChunkName: "component---src-pages-orders-add-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-select-store-js": () => import("./../src/pages/select-store.js" /* webpackChunkName: "component---src-pages-select-store-js" */),
  "component---src-pages-select-tenant-js": () => import("./../src/pages/select-tenant.js" /* webpackChunkName: "component---src-pages-select-tenant-js" */),
  "component---src-pages-stores-add-js": () => import("./../src/pages/stores/add.js" /* webpackChunkName: "component---src-pages-stores-add-js" */),
  "component---src-pages-stores-edit-js": () => import("./../src/pages/stores/edit.js" /* webpackChunkName: "component---src-pages-stores-edit-js" */),
  "component---src-pages-stores-js": () => import("./../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-pages-tenants-add-js": () => import("./../src/pages/tenants/add.js" /* webpackChunkName: "component---src-pages-tenants-add-js" */),
  "component---src-pages-tenants-edit-js": () => import("./../src/pages/tenants/edit.js" /* webpackChunkName: "component---src-pages-tenants-edit-js" */),
  "component---src-pages-tenants-js": () => import("./../src/pages/tenants.js" /* webpackChunkName: "component---src-pages-tenants-js" */),
  "component---src-pages-users-add-js": () => import("./../src/pages/users/add.js" /* webpackChunkName: "component---src-pages-users-add-js" */),
  "component---src-pages-users-edit-js": () => import("./../src/pages/users/edit.js" /* webpackChunkName: "component---src-pages-users-edit-js" */),
  "component---src-pages-users-js": () => import("./../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

